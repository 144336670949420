import { Typography } from 'styles/typography';
import IconNavigate from 'assets/img/icon-navigate.svg';
import IconLocation from 'assets/img/icon-location.svg';
import IconBus from 'assets/img/icon-bus.svg';
import IconCar from 'assets/img/icon-car.svg';
import IconPerson from 'assets/img/icon-person.svg';
import { useEffect, useState } from 'react';
import { locationService } from 'services/location-service';
import { useUserContext } from 'context/user-context';
import { format, add } from 'date-fns';
import { IDirections } from 'models/directions';
import {
  HowToGetContainer,
  LocalizationContainer,
  IconContainer,
  IconStyled,
  LineStyled,
  RouteContainer,
  InfoContainer,
  IconTransportStyled,
  SmallIconBus,
  IconPersonStyled,
} from './styled';

interface IRouteDirectionsProps {
  serviceAddress: string;
  serviceUid: string;
}
const RouteDirections: React.FC<IRouteDirectionsProps> = ({
  serviceAddress,
  serviceUid,
}) => {
  const [address, setAddress] = useState<string>('');
  const [directions, setDirections] = useState<IDirections>();

  const { getGeolocation } = useUserContext();

  useEffect(() => {
    const getDirections = async () => {
      const geoLocation = await getGeolocation();

      if (geoLocation) {
        const fullAddress = await locationService.getFullAddress(
          geoLocation.lat,
          geoLocation.lng,
        );
        const directionsRes = await locationService
          .getServiceDirections(
            fullAddress.formatted_address,
            serviceUid,
            'transit,driving',
          )
          .catch((e) => e);
        setDirections(directionsRes);
        setAddress(fullAddress?.formatted_address || '');
      }
    };
    getDirections();
  }, []);

  return (
    <HowToGetContainer>
      <Typography as="h4" fontSize="body1" lineHeight="24px" margin="0 0 22px">
        Como chegar
      </Typography>
      <LocalizationContainer>
        <IconContainer>
          <IconStyled src={IconNavigate} alt="Ícone" />
          <IconStyled src={IconLocation} alt="Ícone" />
          <LineStyled />
        </IconContainer>
        <div>
          <Typography display="block" lineHeight="24px" margin="-4px 0 8px">
            {address}
          </Typography>
          <Typography
            as="a"
            target="_blank"
            rel="noreferrer noopener"
            textDecoration="underline"
            margin="0 0 24px"
            href={`https://www.google.com/mapss/dir/?api=1&origin=${address}&destination=${serviceAddress}`}
          >
            Editar localização
          </Typography>

          <Typography display="block" lineHeight="24px">
            {serviceAddress}
          </Typography>
        </div>
      </LocalizationContainer>

      {directions?.transit && (
        <RouteContainer>
          <InfoContainer>
            <IconTransportStyled src={IconBus} alt="icone" />
            <Typography fontSize="body1" lineHeight="24px">
              {format(
                directions.transit.departure_time
                  ? directions.transit.departure_time.value * 1000
                  : new Date(),
                'HH:mm',
              )}{' '}
              -{' '}
              {format(
                directions.transit.arrival_time
                  ? directions.transit.arrival_time.value * 1000
                  : add(new Date(), {
                      seconds: directions.transit.duration.value,
                    }),
                'HH:mm',
              )}{' '}
              <Typography display="block">
                {(directions.transit.duration.value / 60).toFixed(0)} min
              </Typography>
            </Typography>
          </InfoContainer>
          <InfoContainer>
            <IconPersonStyled src={IconPerson} />
            {directions.transit.steps.filter(
              (step) => step.travel_mode === 'TRANSIT',
            ).length && (
              <>
                <Typography display="block">{' > '}</Typography>
                <SmallIconBus src={IconBus} />
                <Typography display="block">
                  {directions.transit.steps
                    .filter((step) => step.travel_mode === 'TRANSIT')
                    .map((step) => {
                      return step.transit_details.line.short_name;
                    })
                    .join(' > ')}
                </Typography>
              </>
            )}
          </InfoContainer>
          <Typography
            as="a"
            target="_blank"
            rel="noreferrer noopener"
            textDecoration="underline"
            margin="0 0 24px"
            href={`https://www.google.com/mapss/dir/?api=1&origin=${directions.transit.start_address}&destination=${directions.transit.end_address}&travelmode=transit`}
          >
            Detalhes
          </Typography>
        </RouteContainer>
      )}

      {directions?.driving && (
        <RouteContainer>
          <InfoContainer>
            <IconTransportStyled src={IconCar} alt="icone" />
            <Typography fontSize="body1" lineHeight="24px">
              {format(new Date(), 'HH:mm')} -{' '}
              {format(
                add(new Date(), { seconds: directions.driving.duration.value }),
                'HH:mm',
              )}{' '}
              <Typography display="block">
                {(directions.driving.duration.value / 60).toFixed(0)} min
              </Typography>
            </Typography>
          </InfoContainer>
          <Typography display="block" align="right">
            {directions.driving.distance.text}
          </Typography>
          <Typography
            as="a"
            target="_blank"
            rel="noreferrer noopener"
            textDecoration="underline"
            margin="0 0 24px"
            href={`https://www.google.com/mapss/dir/?api=1&origin=${directions.driving.start_address}&destination=${directions.driving.end_address}&travelmode=driving`}
          >
            Detalhes
          </Typography>
        </RouteContainer>
      )}
    </HowToGetContainer>
  );
};

export default RouteDirections;
