/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Loader from 'components/molecules/loader';
import { useAlert } from 'context/alert-context';
import { useEffect, useState } from 'react';
import { ModalTransition } from 'react-simple-hook-modal';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

const POLL_INTERVAL = 1000 * 10;

const CheckUpdates = () => {
  const [hasUpdates, setHasUpdates] = useState(false);
  const [registration, setRegistration] = useState<ServiceWorkerRegistration>();
  const [isLoading, setIsLoading] = useState(false);

  const { showAlert } = useAlert();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      // registra o service worker para ativar as funcionalidades do PWA
      serviceWorkerRegistration.register();
      // faz uma verificacao inicial de atualizacoes
      checkNewVersion();
      // verifica a cada X segundos se existe uma versao mais recente e exibe um alerta
      setInterval(() => checkNewVersion(), POLL_INTERVAL);
    }
  }, []);

  useEffect(() => {
    if (hasUpdates) {
      showUpdateAlert();
    }
  }, [hasUpdates]);

  const reloadPage = () => {
    const windowEl = window.parent || window;
    windowEl.location.reload(true);
  };

  const forceUpdate = () => {
    if (registration) {
      const registrationWaiting = registration.waiting;
      if (registrationWaiting) {
        setIsLoading(true);

        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e: any) => {
          if (e.target.state === 'activated') {
            reloadPage();
          }
        });
      }
    }
  };

  const showUpdateAlert = () => {
    showAlert({
      title: 'Atenção',
      text: `Uma nova versão do aplicativo está disponível, clique em atualizar para continuar a navegação.`,
      transition: ModalTransition.BOTTOM_UP,
      modalClassName: 'absolute-bottom',
      showCloseButton: false,
      titleProps: {
        align: 'center',
        fontSize: 'body1',
        lineHeight: '120%',
      },
      textProps: {
        align: 'center',
      },
      buttonText: 'Atualizar',
      onButtonClicked: () => forceUpdate(),
    });
  };

  /**
   * Verifica se existe uma versão mais recente do app, através da Service Worker API.
   * https://stackoverflow.com/questions/37573482/to-check-if-serviceworker-is-in-waiting-state
   */
  const checkNewVersion = () => {
    if (document.visibilityState === 'visible') {
      navigator.serviceWorker.ready
        .then((reg) => {
          // se já existe um service worker esperando ativação, exibe o alerta
          if (reg.waiting && reg.active) {
            setRegistration(reg);
            setHasUpdates(true);
          }
          return reg.update().then((_) => reg);
        })
        .then((reg) => {
          setRegistration(reg);

          reg.onupdatefound = () => {
            const installingWorker = reg.installing;
            if (!installingWorker) {
              return;
            }
            installingWorker.onstatechange = () => {
              if (
                installingWorker.state === 'installed' &&
                navigator.serviceWorker.controller
              ) {
                setHasUpdates(true);
              }
            };
          };
        });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return null;
};

export default CheckUpdates;
