import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { responsiveSize } from 'styles/sizes';
import { colors } from 'styles/colors';

interface NewServiceButtonProps {
  hasServices?: boolean;
}

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${responsiveSize(2)};
  padding-bottom: ${responsiveSize(4)};
`;

export const ButtonBell = styled.button``;

export const FilterContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: ${responsiveSize(4)};
`;

export const ServicesContainer = styled.div``;

export const ButtonFilter = styled.button`
  margin-bottom: ${responsiveSize(2)};
  gap: ${responsiveSize(2)};
  display: flex;
`;

export const MyServicesContainer = styled.div`
  position: relative;
  width: calc(100% + 60px);
  margin-left: -30px;
  background-color: #f2f2f2;
  padding: 30px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  .item-container {
    padding-bottom: 40px;
  }

  ul {
    &.slick-dots {
      bottom: 3px;
    }

    li {
      width: 4px;
      button {
        width: 4px;
        height: 4px;
        background: #1d1d1f;
        border-radius: 4px;
        padding: 0;
        &:before {
          content: '';
        }
      }

      &.slick-active {
        width: 24px;
        transition: all 0.3s;
        button {
          transition: all 0.3s;
          width: 24px;
        }
      }
    }
  }
`;

export const NewServiceButton = styled.button<NewServiceButtonProps>`
  background-color: #f92a30;
  padding: 8px 16px;
  margin-top: 21px;
  align-self: flex-start;

  ${({ hasServices }) =>
    hasServices &&
    css`
      align-self: center;
    `}
`;

export const SliderItem = styled.div`
  padding-bottom: 40px;
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
`;

export const StrongStyled = styled.strong`
  color: ${colors.primary};
`;

export const ItemFooter = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;
