/* eslint-disable @typescript-eslint/no-explicit-any */
import Header from 'components/molecules/header';
import { ProfileEnum } from 'enums/profile';
import { StorageKeyEnum } from 'enums/storage-key';
import React, { createContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { FlexContainer } from 'styles/flex-container';
import { IRouteSteps } from './types';

type DefaultType = Record<string, unknown>;

interface ContextProps {
  getData: <T = DefaultType>() => T;
  patchData: <T = DefaultType>(
    data: Partial<T>,
    patchOnServer?: boolean,
  ) => Promise<void>;
  replaceData: <T = DefaultType>(data: Partial<T>) => void;
  clearData: () => void;
  nextStep: () => void;
  goToSuccess: () => void;
  profile?: ProfileEnum;
}

const StepsContext = createContext({} as ContextProps);
export const useStepsContext = () => React.useContext(StepsContext);

interface ProviderProps {
  routes: IRouteSteps[];
  successRoute: string;
  headerTitle: string;
  profile?: ProfileEnum;
  storageKey?: StorageKeyEnum;
  handlePatchData?: (partialData: any, previousData?: any) => Promise<void>;
  onFinish?: () => void;
  initialData?: any;
}
export const StepsContextProvider: React.FC<ProviderProps> = ({
  routes,
  successRoute,
  headerTitle,
  profile,
  storageKey,
  handlePatchData,
  onFinish,
  initialData,
  children,
}) => {
  const [savedData, setSavedData] = useState<DefaultType>(initialData || {});
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (storageKey) {
      const prevData = localStorage.getItem(storageKey);
      if (prevData) {
        setSavedData(JSON.parse(prevData));
      }
    }
    history.replace(routes[0].url);
  }, []);

  const activeIndex = routes.findIndex((page) => page.url === pathname);
  const activeRoute = routes[activeIndex];

  const getData = <T,>() => {
    return savedData as T;
  };

  const replaceData = <T,>(data: Partial<T>) => {
    if (storageKey) localStorage.setItem(storageKey, JSON.stringify(data));
    setSavedData(data as DefaultType);
  };

  const patchData = async <T,>(data: Partial<T>, patchOnServer = false) => {
    if (patchOnServer && handlePatchData) {
      await handlePatchData(data, savedData);
    }
    const newData = { ...savedData, ...data };
    replaceData(newData);
  };

  const clearData = () => {
    if (storageKey) localStorage.removeItem(storageKey);
    setSavedData({});
  };

  const nextStep = () => {
    const next = activeIndex + 1;
    if (next < routes.length) {
      history.push(routes[activeIndex + 1].url);
    } else {
      goToSuccess();
    }
  };

  const goToSuccess = () => {
    clearData();
    history.push(successRoute);
    if (onFinish) onFinish();
  };

  return (
    <StepsContext.Provider
      value={{
        getData,
        patchData,
        replaceData,
        clearData,
        nextStep,
        goToSuccess,
        profile,
      }}
    >
      <FlexContainer as="section" darkMode={activeRoute?.contrastHeader}>
        {!activeRoute?.hideHeader && (
          <Header
            title={headerTitle}
            progress={((activeIndex + 1) / routes.length) * 100}
            contrast={activeRoute?.contrastHeader}
          />
        )}

        <Switch>
          {routes.map((page) => (
            <Route
              key={page.url}
              exact={!!page.exact}
              path={page.url}
              component={page.component}
            />
          ))}
        </Switch>
        {children}
      </FlexContainer>
    </StepsContext.Provider>
  );
};
