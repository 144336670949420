import { useMemo } from 'react';
import ChooseProviderPage from 'components/templates/choose-provider-page';
import { useAsync } from 'context/async-context';
import { useStepsContext } from 'context/steps-context';
import { LoginProviderEnum } from 'enums/login-provider';
import { capitalizeText } from 'helpers';
import { ILead } from 'models/lead';
import { useHistory } from 'react-router-dom';
import { firebaseService } from 'services/firebase-service';

const RegisterChooseProvider = () => {
  const history = useHistory();
  const { nextStep, replaceData } = useStepsContext();
  const { handledAsync } = useAsync();

  const pageTitle = useMemo(() => {
    const isCustomer = history.location.pathname === '/cadastro-cliente';
    return `Criar conta como ${isCustomer ? 'cliente' : 'prestador'}`;
  }, [history]);

  const socialLogin = async (type: LoginProviderEnum) => {
    const capitalizedType = capitalizeText(
      Object.keys(LoginProviderEnum)[
        Object.values(LoginProviderEnum).indexOf(type)
      ],
    );

    handledAsync(
      async () => {
        const userCredential = await firebaseService.socialLogin(type);
        if (userCredential) {
          replaceData<ILead>({
            lead_name: userCredential.user?.displayName || '',
          });
          nextStep();
        }
      },
      () =>
        `Ocorreu um erro ao fazer login usando sua conta do ${capitalizedType}.`,
    );
  };

  return (
    <>
      <ChooseProviderPage
        heading={pageTitle}
        socialLoginClicked={socialLogin}
        mainActionText="Ou crie com os seus dados"
        mainButtonClicked={nextStep}
        mainButtonTestId="register-phone-password"
        secondaryButtonText="Já tenho conta"
        secondaryButtonClicked={() => history.push('/login')}
      />
    </>
  );
};

export default RegisterChooseProvider;
