import { useState } from 'react';
import { Typography } from 'styles/typography';
import InputName from 'components/atoms/input-name';
import { Button } from 'components/atoms/button';
import { useStepsContext } from 'context/steps-context';
import { ILead } from 'models/lead';
import { responsiveSize } from 'styles/sizes';

const RegisterName = () => {
  const [inputValue, setInputValue] = useState('');
  const { getData, patchData, nextStep } = useStepsContext();
  const savedData = getData<ILead>();

  const btnClicked = async () => {
    patchData<ILead>({ lead_name: inputValue });
    nextStep();
  };

  return (
    <>
      <Typography
        as="h3"
        color="title"
        fontSize="heading3"
        fontWeight="bold"
        display="block"
        letterSpacing="-0.01em"
        margin={`0 0 ${responsiveSize(5)}`}
      >
        Qual o seu nome?
      </Typography>
      <InputName
        placeholder="Digite seu nome completo"
        onChange={setInputValue}
        initialData={savedData?.lead_name}
      />
      <Button disabled={!inputValue} onClick={btnClicked}>
        Continuar
      </Button>
    </>
  );
};

export default RegisterName;
